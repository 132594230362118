@font-face {
    font-family: DM Sans;
    src: url(./assets/fonts/DMSans-Regular.ttf);
}
@font-face {
    font-family: DM Sans-Bold;
    src: url(./assets/fonts/DMSans-Bold.ttf);
}
/* .row{
    --bs-gutter-x: 1.5rem !important;
} */
.unique-gutter{
    --bs-gutter-x: 0rem !important;
}
body{
    font-family: DM Sans !important;
    overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6{
    font-family: DM Sans-Bold !important;
    }
    h1{
        color: #2d3958;
    }
p{
    font-size: 1.1rem;
    color: #2d3958;
}
.p-5{
    padding: 6rem !important;
}
.bg-body-tertiary {
    background-color: #fff !important;
}
.nav-link {
    font-size: 1 rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #2d3958;
}
.why-ams-bg{
    background: #f8fafc url("./assets/images/ams-why-ams-bg-pattern.png") no-repeat;

}
.carousel-caption p{
    color:#fff;
}
footer {
    background-color: #014898;
}
.text-justify{
    text-align: justify;
}
.text-white-footer{
    color: #e1dada;
}
.text-white-footer:hover{
    color: #fff;
}
.footer-address{
    border-top:1px solid #0f63b7;
    background-color: #002d60;
    padding: 20px 0px 15px 0px;
}
.footer-address h4{
    color: #ea5706;
    font-size: 1.2em;
}
.footer-address p{
    color: #bbb5b5;
    margin-bottom: .2rem;
    text-transform: uppercase;
    font-size: 1.1rem;
}
/* .card{
    padding: 1rem 0;
} */
.card-title{
    color: #014898;
}
#home-services .card{
    background-color: #fff !important;    
}
#home-services .card-body{
    padding: 1.8rem;    
}
.h5, h5 {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
}
.about_flex01 .about_1,.about_flex01 figcaption{
display: none;
}
.about_flex11 {
    justify-content:space-between;
    gap: 2rem;
}
.about-col-1{
    width:100% !important;
}
.ams-card {
    padding: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.ams-card-header {
    border-radius: 0px !important;
    padding: 1rem;
    background-color: #014898;
    color: #fff;
}

.course-title {
    background-color: #014898;
    color: rgb(255, 255, 255);
    padding: 8px 0px;
    border-radius: 50px;
    width: 20rem;
    margin: -43px auto 0 auto;
}
.x-margin{
    margin: 1rem;
}
.table-custom-bg {
    --bs-table-bg: #ebeff1 !important;
}
.dropdown-menu.show {
    border-radius: 0px;
    padding: 0px;
    margin: 0px;
    border: none;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #ffffff;
    background-color: #014898;
}
.nav-link:focus, .nav-link:hover {
    color: rgb(1 72 152);
    font-weight: bold;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: rgb(1 72 152);
}
.gallery{
    padding-top:2rem !important;
}
.navbar-nav .nav-item:hover .dropdown-menu {
    display: block !important;
  }
.bi-search {
    font-size: 2rem;
    color: orange;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 2rem 0 3rem 0;
  }
  
  .pagination a {
    margin: 0 5px;
    padding: 10px 15px;
    text-decoration: none;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .pagination a:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .pagination .active a {
    background-color: #007bff;
    color: #fff;
  }
  

/* LogoSlider.css */

.logo-slider {
    overflow: hidden;
    width: 100%;

    margin: 0 auto;
  }
  
  .logo-container {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .logo {
    width: 100%;
    height: auto;
  }
  
  .slick-slide img {
    padding: 1rem;
}



/* Media Queries */
@media (min-width: 768px){
.p-md-5 {
    padding: 4rem 6rem!important;
}
.col-sm-12{
    margin-bottom: 0px !important;
}
}
@media (min-width: 576px){
    .col-sm-12{
        margin-bottom: 20px;
    }
    }

@media (max-width: 576px){
.p-sm-5 {
    padding: 2rem;
}
.carousel-item img{
    height: 285px;
}
.px-sm-2 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
}
.col-sm-12{
    margin-bottom: 20px;
}
}